import { Node } from '@xyflow/react';
import { nanoid } from 'nanoid/non-secure';
import { ProfilerOnRenderCallback } from 'react';
import { LayerNode } from './Layer';
import { Snapshot } from './types';

export function roundto(value: number, precision = 2) {
  const factor = 10 ** precision;
  return Math.round(value * factor) / factor;
}

export function genid() {
  return nanoid(14);
}

export function isLayerNode(node?: Node): node is LayerNode {
  return node?.type === 'layer';
}

export const onRender: ProfilerOnRenderCallback = (
  id,
  phase,
  actualTime,
  baseTime,
  startTime,
  commitTime
) => {
  console.log({
    id,
    phase,
    actualTime: roundto(actualTime),
    baseTime: roundto(baseTime),
  });
};

export function isSnapshot(snapshot: any): snapshot is Snapshot {
  return (
    snapshot &&
    Array.isArray(snapshot.nodes) &&
    Array.isArray(snapshot.edges) &&
    snapshot.viewport
  );
}
