import { ArrowBack, Refresh, Start } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/layout/PageHeader';
import { useInstances, useOffers } from '../utils/api/instances';
import { LoadingButton } from './Loading';

export default function Offers() {
  const navigate = useNavigate();
  const { deploy } = useInstances();
  const { offers, error, isLoading, isValidating, mutate } = useOffers();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'offer_id',
        headerName: 'Offer ID',
      },
      {
        field: 'gpu',
        headerName: 'GPU',
        sortable: false,
        hideable: false,
        disableColumnMenu: true,
        minWidth: 200,
        flex: 1,
        renderCell: (params) => {
          const { gpu_name, gpu_count } = params.row;
          if (gpu_count === 1) return gpu_name;
          return `${gpu_count} × ${gpu_name}`;
        },
      },
      {
        field: 'gpu_tflops',
        headerName: 'TFLOPS',
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (value: number) => `${value.toFixed(2)}`,
      },
      {
        field: 'vram_mb',
        headerName: 'VRAM/GPU',
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (value: number) => {
          const vram_gb = value / 1024;
          return `${vram_gb.toFixed(1)} GB`;
        },
      },
      {
        field: 'cuda_version',
        headerName: 'CUDA Ver.',
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'vcpu',
        headerName: 'vCPU',
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (value: number) => `${value.toFixed(1)}`,
      },
      {
        field: 'disk_space_gb',
        headerName: 'Disk Space',
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (value: number) => `${value.toFixed(0)} GB`,
      },
      {
        field: 'cost_dph',
        headerName: '$/hr',
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (value: number) => `${value.toFixed(3)}`,
      },
      {
        field: 'deploy',
        headerName: 'Deploy',
        hideable: false,
        sortable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const { offer_id } = params.row;
          return (
            <Tooltip title={offer_id}>
              <IconButton
                color="primary"
                size="small"
                onClick={() => deploy(offer_id)}
              >
                <Start />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [deploy]
  );

  return (
    <Container>
      <PageHeader
        title="Available Instances"
        subtitle="Select an instance to deploy."
        actions={
          <ButtonGroup variant="contained">
            <Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
              Go back
            </Button>
            <LoadingButton
              loading={isValidating}
              onClick={() => mutate()}
              startIcon={<Refresh />}
            >
              Refresh
            </LoadingButton>
          </ButtonGroup>
        }
      />
      <DataGrid
        sx={{ height: 600 }}
        loading={isLoading}
        slotProps={{
          loadingOverlay: {
            variant: 'circular-progress',
            noRowsVariant: 'skeleton',
          },
        }}
        columns={columns}
        rows={offers}
        getRowId={(offer) => offer.offer_id}
        disableRowSelectionOnClick
        pageSizeOptions={[16, 32, 64]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 16,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'cost_dph',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </Container>
  );
}
