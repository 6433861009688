import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { SnackbarProvider } from 'notistack';
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { ColorModeContextProvider } from './components/ColorMode';
import SideNavbar from './components/SideNavbar';
import ModelBuilder from './model_builder/ModelBuilder';
import DatasetDashboard from './pages/dataset/DatasetDashboard';
import DatasetUpload from './pages/dataset/DatasetUpload';
import Offers from './pages/Deploy';
import HomePage from './pages/home/HomePage';
import InferenceDashboard from './pages/inference/InferenceDashboard';
import Instances from './pages/Instances';
import InstanceDashboard from './pages/instances/InstanceDashboard';
import UserInstanceDashboard from './pages/instances/UserInstanceDashboard';
import LiveTrainingDashboard from './pages/liveTraining/LiveTrainingDashboard';
import Login from './pages/Login';
import ModelDashboard from './pages/model/ModelDashboard';
import ModelParameters from './pages/ModelParameters';
import Report from './pages/Report';
import { useUser } from './utils/api/user';

const theme = createTheme({
  palette: {
    primary: deepPurple,
    secondary: deepPurple,
  },
  components: {
    MuiListItemIcon: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ColorModeContextProvider>
        <CssBaseline />
        <SnackbarProvider
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/build" element={<ModelBuilder />} />
            <Route element={<SidebarLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/dataset" element={<DatasetDashboard />} />
              <Route path="/dataset_upload" element={<DatasetUpload />} />
              <Route path="/hyperparameters" element={<ModelParameters />} />
              <Route
                path="/instancedashboard"
                element={<InstanceDashboard />}
              />
              <Route path="/myinstances" element={<UserInstanceDashboard />} />
              {/* <Route path="/dataset/model_dashboard" element={<ModelDashboard />} /> */}
              <Route
                path="/live_training"
                element={<LiveTrainingDashboard />}
              />
              <Route path="/model_dashboard" element={<ModelDashboard />} />
              <Route path="/inference" element={<InferenceDashboard />} />
              <Route path="/report" element={<Report />} />
              <Route path="/instances">
                <Route index element={<Instances />} />
                <Route path="deploy" element={<Offers />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </ColorModeContextProvider>
    </ThemeProvider>
  );
}

function SidebarLayout() {
  useUser();

  return (
    <>
      <SideNavbar />
      <Box ml={{ md: '250px' }}>
        <Outlet />
      </Box>
    </>
  );
}
