import { Box, Stack, Typography } from '@mui/material';

type PageHeaderProps = {
  title: string;
  subtitle: string;
  actions?: React.ReactNode;
};

export default function PageHeader({
  title,
  subtitle,
  actions,
}: PageHeaderProps) {
  return (
    <Stack
      mb={{ xs: 2, md: 4 }}
      direction="row"
      alignItems="end"
      rowGap={1}
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box>
        <Typography
          sx={{
            typography: { xs: 'h4', md: 'h3' },
          }}
        >
          {title}
        </Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
      {actions}
    </Stack>
  );
}
