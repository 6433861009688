import axios, { AxiosError } from 'axios';
import { ErrorResponse, TokenResponse } from '../../types/models';

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      const _token = JSON.parse(token) as TokenResponse;
      config.headers['Authorization'] = `Bearer ${_token.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response.data,
  async (error: Error | AxiosError) => {
    if (!axios.isAxiosError(error) || !error.response) {
      console.error(error);
      return Promise.reject({
        code: 'network_error',
        detail:
          'A network error occurred. Please check your connection and try again.',
      } as ErrorResponse);
    }

    if (error.response.status >= 500) {
      return Promise.reject({
        code: 'server_error',
        detail: 'An error occurred on the server. Please try again later.',
      } as ErrorResponse);
    }

    return Promise.reject(error.response?.data);
  }
);

export default client;
