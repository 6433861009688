import {
  Edge,
  Node,
  OnSelectionChangeFunc,
  useOnSelectionChange,
  useReactFlow,
} from '@xyflow/react';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ContextMenuState } from './ContextMenu';
import { Snapshot } from './types';
import { isLayerNode, isSnapshot } from './util';

export function useForceUpdate() {
  const [, setTick] = useState(0);
  return () => setTick((tick) => tick + 1);
}

export function useLayerArgs(nodeId: string) {
  const { getNode, updateNodeData } = useReactFlow();
  const [args, _setArgs] = useState<Record<string, any>>({});

  useEffect(() => {
    const node = getNode(nodeId);
    if (node && isLayerNode(node)) {
      _setArgs(node.data.arguments || {});
    }
  }, [getNode, nodeId]);

  const setArgs = (newArgs: Record<string, any>) => {
    _setArgs(newArgs);
    updateNodeData(nodeId, { arguments: newArgs });
  };

  return [args, setArgs] as const;
}

export function useSelectionState() {
  const [selection, setSelection] = useState<{ nodes: Node[]; edges: Edge[] }>({
    nodes: [],
    edges: [],
  });

  const onSelectionChange: OnSelectionChangeFunc = useCallback(
    (selection) => setSelection(selection),
    []
  );

  useOnSelectionChange({ onChange: onSelectionChange });

  return selection;
}

export function useContextMenu() {
  const [menuState, setMenuState] = useState<ContextMenuState>({
    open: false,
    clientX: 0,
    clientY: 0,
    type: 'pane',
  });

  const closeMenu = () => setMenuState({ ...menuState, open: false });

  const onPaneContextMenu = useCallback(
    (event: React.MouseEvent | MouseEvent) => {
      event.preventDefault();
      setMenuState({
        open: true,
        clientX: event.clientX,
        clientY: event.clientY,
        type: 'pane',
      });
    },
    [setMenuState]
  );

  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent | MouseEvent, node: Node) => {
      event.preventDefault();
      setMenuState({
        open: true,
        clientX: event.clientX,
        clientY: event.clientY,
        type: 'node',
        node,
      });
    },
    [setMenuState]
  );

  const onEdgeContextMenu = useCallback(
    (event: React.MouseEvent | MouseEvent, edge: Edge) => {
      event.preventDefault();
      setMenuState({
        open: true,
        clientX: event.clientX,
        clientY: event.clientY,
        type: 'edge',
        edge,
      });
    },
    [setMenuState]
  );

  return {
    menuState,
    closeMenu,
    onPaneContextMenu,
    onNodeContextMenu,
    onEdgeContextMenu,
  };
}

export function usePersistence() {
  const { getEdges, getNodes, getViewport, setEdges, setNodes, setViewport } =
    useReactFlow();

  const capture = useCallback(() => {
    return {
      nodes: getNodes(),
      edges: getEdges(),
      viewport: getViewport(),
    } as Snapshot;
  }, [getEdges, getNodes, getViewport]);

  const restore = useCallback(
    (snapshot: Snapshot) => {
      setNodes(snapshot.nodes);
      setEdges(snapshot.edges);
      setViewport(snapshot.viewport);
    },
    [setEdges, setNodes, setViewport]
  );

  const save = useCallback(() => {
    const state = capture();
    localStorage.setItem('save', JSON.stringify(state));
    enqueueSnackbar('Saved successfully', { variant: 'success' });
  }, [capture]);

  const load = useCallback(() => {
    const state = localStorage.getItem('save');
    if (!state) return;
    try {
      const snapshot = JSON.parse(state) as Snapshot;
      restore(snapshot);
      enqueueSnackbar('Loaded successfully', { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Failed to load save', { variant: 'error' });
    }
  }, [restore]);

  const download = useCallback(() => {
    const state = capture();
    const blob = new Blob([JSON.stringify(state, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `snapshot-${new Date().toISOString()}.json`;
    a.click();
    enqueueSnackbar('Download started', { variant: 'info' });
  }, [capture]);

  const upload = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const snapshot = JSON.parse(reader.result as string);
          if (!isSnapshot(snapshot)) throw new Error('Invalid snapshot');
          restore(snapshot);
          enqueueSnackbar('Imported successfully', { variant: 'success' });
        } catch (e) {
          console.error(e);
          enqueueSnackbar(`Failed to import ${file.name}`, {
            variant: 'error',
          });
        }
      };
      reader.readAsText(file);
    },
    [restore]
  );

  return { capture, restore, save, load, download, upload };
}

const MAX_HISTORY = 100;

export function useFlowHistory() {
  const { capture, restore } = usePersistence();
  const history = useRef<Snapshot[]>([capture()]);
  const index = useRef(0);

  useEffect(() => {
    const saved = localStorage.getItem('history');
    if (!saved) return;
    try {
      history.current = JSON.parse(saved);
      index.current = history.current.length;
    } catch (e) {
      enqueueSnackbar('Failed to load history', { variant: 'error' });
      console.error(e);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('history', JSON.stringify(history.current));
  }, [history]);

  const push = useCallback(() => {
    // Truncate history if we're not at the end
    if (index.current < history.current.length) {
      history.current = history.current.slice(0, index.current);
    }

    history.current.push(capture());

    // Truncate history if we're over the limit
    if (history.current.length > MAX_HISTORY) {
      history.current = history.current.slice(1);
    }

    index.current = history.current.length;
  }, [capture]);

  const undo = useCallback(() => {
    if (index.current === 0) return;
    index.current--;
    restore(history.current[index.current]);
  }, [restore]);

  const redo = useCallback(() => {
    if (index.current === history.current.length) return;
    index.current++;
    restore(history.current[index.current]);
  }, [restore]);

  return { history: history.current, push, undo, redo };
}
