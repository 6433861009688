import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TokenResponse } from '../types/models';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, api) => {
    const token = localStorage.getItem('token');
    if (token) {
      const _token = JSON.parse(token) as TokenResponse;
      headers.set('Authorization', `Bearer ${_token.token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    'Datasets',
    'DeployedInstances',
    'PastInstances',
    'TrainedModelMetrics',
    'ModelParameters',
    'TrainedModelDataset',
  ],
  endpoints: (builder) => ({}),
});
