import { Brightness4, Brightness7 } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  addEdge,
  Background,
  BackgroundVariant,
  ControlButton,
  Controls,
  Edge,
  EdgeTypes,
  MiniMap,
  Node,
  NodeTypes,
  OnConnect,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import {
  DragEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useColorMode } from '../components/ColorMode';
import { ClipboardProvider, useClipboard } from './Clipboard';
import { ContextMenu } from './ContextMenu';
import { DebugPanel } from './DebugPanel';
import { Layer } from './Layer';
import { useContextMenu, usePersistence, useSelectionState } from './hooks';
import Inspector from './inspector/Inspector';
import { genid } from './util';

export default function ModelBuilder() {
  return (
    <ReactFlowProvider>
      <ClipboardProvider>
        <ModelBuilderFlow />
      </ClipboardProvider>
    </ReactFlowProvider>
  );
}

function ModelBuilderFlow() {
  const { mode, toggleMode } = useColorMode();
  const [nodeTypes, setNodeTypes] = useState<NodeTypes>({ layer: Layer });
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edgeTypes, setEdgeTypes] = useState<EdgeTypes>({});
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { addNodes, fitView, deleteElements } = useReactFlow();
  const { save, load, upload, download } = usePersistence();
  const { clipboard, setClipboard } = useClipboard();

  useEffect(() => {
    load();
  }, [load]);

  const onConnect: OnConnect = useCallback(
    (connection) => {
      setEdges((edges) => addEdge(connection, edges));
    },
    [setEdges]
  );

  const onDrop: DragEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (e.dataTransfer.files.length === 0) return;
      const file = e.dataTransfer.files[0];
      upload(file);
    },
    [upload]
  );

  const onDragOver: DragEventHandler = useCallback((e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }, []);

  const selection = useSelectionState();
  const {
    menuState,
    closeMenu,
    onPaneContextMenu,
    onNodeContextMenu,
    onEdgeContextMenu,
  } = useContextMenu();

  const [showDebug, setShowDebug] = useState(false);

  const onKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.ctrlKey) {
        if (e.key === 'c') {
          e.preventDefault();
          setClipboard(selection.nodes);
        }

        if (e.key === 'v') {
          e.preventDefault();
          const nodes = clipboard.map((node) => ({ ...node, id: genid() }));
          addNodes(nodes);
        }

        if (e.key === 'x') {
          e.preventDefault();
          console.log('Cut');
        }

        if (e.key === 'z') {
          e.preventDefault();
          console.log('Undo');
        }

        if (e.shiftKey && e.key === 'Z') {
          e.preventDefault();
          console.log('Redo');
        }

        if (e.key === 's') {
          e.preventDefault();
          save();
        }

        if (e.shiftKey && e.key === '?') {
          e.preventDefault();
          setShowDebug((show) => !show);
        }
      } else {
        if (e.key === 'Delete') {
          e.preventDefault();
          deleteElements(selection);
        }
      }
    },
    [setClipboard, selection, clipboard, addNodes, save, deleteElements]
  );

  return (
    <Box width="100%" height="100vh" onKeyDown={onKeyDown} tabIndex={0}>
      <ReactFlow
        colorMode={mode}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onPaneContextMenu={onPaneContextMenu}
        onNodeContextMenu={onNodeContextMenu}
        onEdgeContextMenu={onEdgeContextMenu}
        onDrop={onDrop}
        onDragOver={onDragOver}
        fitViewOptions={{ maxZoom: 1 }}
        fitView
      >
        <Controls
          position="bottom-left"
          orientation="vertical"
          style={{ left: '208px' }}
          onFitView={() => fitView({ nodes: selection.nodes })}
        >
          <ControlButton onClick={toggleMode} title="toggle color mode">
            {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
          </ControlButton>
        </Controls>
        <MiniMap position="bottom-left" />
        <Inspector
          position="top-right"
          direction="left"
          style={{ bottom: 0 }}
          selection={selection}
        />
        <Background
          variant={BackgroundVariant.Dots}
          gap={20}
          size={1}
          bgColor={mode === 'dark' ? '#141414' : '#f9f9f9'}
          color={mode === 'dark' ? '#444' : '#999'}
        />
        <ContextMenu {...menuState} onClose={closeMenu} />
        <DebugPanel
          position="top-left"
          direction="down"
          show={showDebug}
          style={{
            left: -15,
            top: -15,
            height: '100%',
          }}
        />
      </ReactFlow>
    </Box>
  );
}
