import { Node } from '@xyflow/react';
import { createContext, useCallback, useContext, useState } from 'react';
import { ClipboardContextProps, ClipboardProviderProps } from './types';
import { genid, isLayerNode } from './util';

const ClipboardContext = createContext<ClipboardContextProps>({
  clipboard: [],
  setClipboard: () => {},
});

const { Provider } = ClipboardContext;

export const ClipboardProvider = ({ children }: ClipboardProviderProps) => {
  const [clipboard, _setClipboard] = useState<Node[]>([]);

  const setClipboard = useCallback((nodes: Node[]) => {
    nodes = nodes.filter(isLayerNode);
    _setClipboard(nodes);
  }, []);

  return <Provider value={{ clipboard, setClipboard }}>{children}</Provider>;
};

export const useClipboard = () => {
  const { clipboard, setClipboard } = useContext(ClipboardContext);
  return { clipboard, setClipboard };
};
