import activation from './activation.json';
import core from './core.json';
import io from './io.json';
import model from './model.json';
import op from './op.json';
import transform from './transform.json';

const data = {
  activation,
  core,
  io,
  model,
  op,
  transform,
};

export default data;
