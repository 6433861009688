import {
  Alert,
  Box,
  darken,
  FormControl,
  FormLabel,
  Card as MuiCard,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ToggleColorModeButton } from '../components/ColorMode';
import { useLogin } from '../utils/api/user';
import { LoadingButton } from './Loading';

const LoginContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: darken(theme.palette.background.default, 0.1),
  padding: theme.spacing(2),
}));

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: theme.spacing(4),
  margin: 'auto',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
}));

export default function Login() {
  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const username = form.username.value;
    const password = form.password.value;
    await login(username, password);
  };

  return (
    <LoginContainer>
      <Card variant="outlined">
        <Box position="absolute" top={0} right={0} p={2}>
          <ToggleColorModeButton />
        </Box>
        <Typography variant="h4" gutterBottom>
          Sign in
        </Typography>
        <Stack component="form" gap={2} onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel htmlFor="username">Username</FormLabel>
            <TextField
              id="username"
              name="username"
              type="text"
              autoFocus
              required
              fullWidth
              variant="standard"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              id="password"
              name="password"
              type="password"
              required
              fullWidth
              variant="standard"
            />
          </FormControl>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Sign in
          </LoadingButton>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {`${error.detail as string}`}
            </Alert>
          )}
        </Stack>
      </Card>
    </LoginContainer>
  );
}
