import {
  AddCircle,
  Calculate,
  ChevronRight,
  Clear,
  ContentCopy,
  ContentCut,
  ContentPaste,
  Delete,
  Download,
  Functions,
  Layers,
  Polyline,
  Save,
  Sync,
  SyncAlt,
  Transform,
  Upload,
} from '@mui/icons-material';
import {
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  PaperProps,
  Popper,
  styled,
} from '@mui/material';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useClipboard } from './Clipboard';
import data from './data';
import { usePersistence } from './hooks';
import { LayerData } from './Layer';
import { genid } from './util';

export type ContextMenuState = {
  open: boolean;
  type: 'node' | 'edge' | 'pane';
  clientX: number;
  clientY: number;
  node?: Node;
  edge?: Edge;
  nodes?: Node[];
};

export type ContextMenuProps = ContextMenuState & {
  onClose: () => void;
};

export function ContextMenu(props: ContextMenuProps) {
  const { open, clientX, clientY, type, onClose } = props;

  const Items = useMemo(() => {
    switch (type) {
      case 'node':
        return NodeContextMenuItems;
      case 'edge':
        return EdgeContextMenuItems;
      default:
        return PaneContextMenuItems;
    }
  }, [type]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Menu
        open={open}
        transitionDuration={200}
        MenuListProps={{ dense: true }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: clientY, left: clientX }}
        onContextMenu={(e) => e.preventDefault()}
        slotProps={{
          root: { sx: { pointerEvents: 'none' } },
          paper: { sx: { pointerEvents: 'auto' } },
        }}
      >
        <Items {...props} />
      </Menu>
    </ClickAwayListener>
  );
}

const NodeContextMenuItems = memo((props: ContextMenuProps) => {
  const { node, onClose } = props;
  const { deleteElements } = useReactFlow();
  const { setClipboard } = useClipboard();

  const onCopy = useCallback(() => {
    if (node) setClipboard([node]);
    onClose();
  }, [node, onClose, setClipboard]);

  const onCut = useCallback(() => {
    if (node) {
      setClipboard([node]);
      deleteElements({ nodes: [node] });
    }
    onClose();
  }, [node, onClose, setClipboard, deleteElements]);

  const onDelete = useCallback(() => {
    if (node) deleteElements({ nodes: [node] });
    onClose();
  }, [node, deleteElements, onClose]);

  return [
    <MenuOption
      key="copy"
      primaryText="Copy"
      icon={<ContentCopy />}
      onClick={onCopy}
    />,
    <MenuOption
      key="cut"
      primaryText="Cut"
      icon={<ContentCut />}
      onClick={onCut}
    />,
    <MenuOption
      key="delete"
      primaryText="Delete"
      icon={<Delete />}
      onClick={onDelete}
    />,
  ];
});

const EdgeContextMenuItems = memo((props: ContextMenuProps) => {
  const { edge, onClose } = props;
  const { deleteElements } = useReactFlow();

  const onDelete = useCallback(() => {
    if (edge) deleteElements({ edges: [edge] });
    onClose();
  }, [edge, deleteElements, onClose]);

  return [
    <MenuOption
      key="delete"
      primaryText="Delete"
      icon={<Delete />}
      onClick={onDelete}
    />,
  ];
});

const FileUpload = styled('input')({
  position: 'absolute',
  opacity: 0,
  inset: 0,
});
FileUpload.defaultProps = { type: 'file', title: '' };

const PaneContextMenuItems = memo((props: ContextMenuProps) => {
  const { addNodes, screenToFlowPosition, setNodes } = useReactFlow();
  const { clientX, clientY } = props;
  const { clipboard } = useClipboard();
  const { save, download, load, upload } = usePersistence();

  const onClick = (data: LayerData) => (e: React.MouseEvent) => {
    const position = screenToFlowPosition({ x: clientX - 25, y: clientY - 25 });
    data.arguments = {};
    addNodes([{ id: genid(), type: 'layer', data, position }]);
  };

  const renderOptions = (data: LayerData[]) =>
    data.map((data) => (
      <MenuOption
        key={data.name}
        primaryText={data.name}
        onClick={onClick(data)}
      />
    ));

  const onPaste = () => {
    const position = screenToFlowPosition({ x: clientX - 25, y: clientY - 25 });
    const nodes = clipboard.map((node) => ({ ...node, id: genid(), position }));
    addNodes(nodes);
  };

  const onImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || !files[0]) return;
    upload(files[0]);
  };

  const onClear = () => {
    setNodes([]);
  };

  return [
    <MenuGroup key="add" primaryText="Add Node" icon={<AddCircle />}>
      {[
        <MenuGroup key="add-io" primaryText="IO" icon={<SyncAlt />}>
          {renderOptions(data.io)}
        </MenuGroup>,
        <MenuGroup key="add-model" primaryText="Model" icon={<Layers />}>
          {renderOptions(data.model)}
        </MenuGroup>,
        <MenuGroup key="add-layer" primaryText="Layer" icon={<Polyline />}>
          {renderOptions(data.core)}
        </MenuGroup>,
        <MenuGroup key="add-proc" primaryText="Transform" icon={<Transform />}>
          {renderOptions(data.transform)}
        </MenuGroup>,
        <MenuGroup
          key="add-activation"
          primaryText="Activation"
          icon={<Functions />}
        >
          {renderOptions(data.activation)}
        </MenuGroup>,
        <MenuGroup key="add-op" primaryText="Operation" icon={<Calculate />}>
          {renderOptions(data.op)}
        </MenuGroup>,
      ]}
    </MenuGroup>,
    <Divider key="divider" />,
    <MenuOption
      key="paste"
      primaryText="Paste"
      icon={<ContentPaste />}
      onClick={onPaste}
    />,
    <Divider key="divider2" />,
    <MenuOption key="save" primaryText="Save" icon={<Save />} onClick={save} />,
    <MenuOption
      key="restore"
      primaryText="Restore"
      icon={<Sync />}
      onClick={load}
    />,
    <MenuOption key="import" icon={<Upload />} primaryText="Import">
      <FileUpload onChange={onImport} />
    </MenuOption>,
    <MenuOption
      key="export"
      primaryText="Export"
      icon={<Download />}
      onClick={download}
    />,
    <Divider key="divider3" />,
    <MenuOption
      key="clear"
      primaryText="Clear"
      icon={<Clear />}
      onClick={onClear}
    />,
  ];
});

export type MenuGroupProps = {
  children: React.ReactNode[];
  primaryText: string;
  secondaryText?: string;
  icon?: React.ReactNode;
  PaperProps?: PaperProps;
} & Omit<MenuItemProps, 'children'>;

const StyledMenuPaper = styled(Paper)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.divider} ${theme.palette.background.default}`,
}));
StyledMenuPaper.defaultProps = { elevation: 8 };

export function MenuGroup(props: MenuGroupProps) {
  const { children, primaryText, secondaryText, icon, PaperProps, ...rest } =
    props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLLIElement>(null);

  const onMouseEnter = () => setOpen(true);
  const onMouseLeave = () => setOpen(false);

  return (
    <MenuItem
      disableRipple
      disableTouchRipple
      {...rest}
      sx={{ cursor: 'default', ...rest.sx }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={anchorRef}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primaryText} secondary={secondaryText} />
      <ChevronRight sx={{ ml: 2 }} />
      <Popper
        sx={{ zIndex: 1320 }}
        open={open}
        anchorEl={anchorRef.current}
        placement="right-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <StyledMenuPaper {...PaperProps}>
              <MenuList dense>{children}</MenuList>
            </StyledMenuPaper>
          </Grow>
        )}
      </Popper>
    </MenuItem>
  );
}

export type MenuOptionProps = {
  primaryText: string;
  secondaryText?: string;
  icon?: React.ReactNode;
} & MenuItemProps;

export function MenuOption(props: MenuOptionProps) {
  const { children, primaryText, secondaryText, icon, ...rest } = props;

  return (
    <MenuItem {...rest}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primaryText} secondary={secondaryText} />
      {children}
    </MenuItem>
  );
}
