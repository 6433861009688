import { AddCircle, Refresh } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import InstanceCard from '../components/instances/InstanceCard';
import PageHeader from '../components/layout/PageHeader';
import { useInstances } from '../utils/api/instances';
import { LoadingButton } from './Loading';
import { useEffect } from 'react';

export default function Instances() {
  const { instances, error, isLoading, isValidating, refresh } = useInstances();
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash || !instances) return;
    const instance = document.getElementById(hash.slice(1));
    if (instance) instance.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <Container>
      <PageHeader
        title="Instances"
        subtitle="Manage your deployed instances."
        actions={
          <ButtonGroup>
            <LoadingButton
              variant="contained"
              startIcon={<Refresh />}
              onClick={refresh}
              loading={isValidating}
              sx={{ position: 'relative' }}
            >
              Refresh
            </LoadingButton>
            <Button
              variant="contained"
              startIcon={<AddCircle />}
              component={Link}
              to="/instances/deploy"
            >
              Add Instance
            </Button>
          </ButtonGroup>
        }
      />
      <Grid container spacing={2} overflow={'auto'}>
        {!!isLoading &&
          Array.from({ length: 4 }).map((_, i) => (
            <Grid item key={i} xs={12}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Grid>
          ))}
        {!!instances && instances.length === 0 && (
          <Box sx={{ textAlign: 'center', width: '100%', py: 8 }}>
            <Typography variant="h6">No instances deployed</Typography>
          </Box>
        )}
        {instances?.map((instance) => (
          <Grid
            item
            key={instance.instance_id}
            id={instance.instance_id.toString()}
            xs={12}
          >
            <InstanceCard instance={instance} isLoading={isValidating} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
