import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';

export const CenteredCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  margin: 'auto',
  inset: 0,
});

export const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
});

export const LoadingButton = ({
  loading,
  ref,
  children,
  inputRef,
  ...props
}: ButtonProps & {
  inputRef?: React.RefObject<HTMLButtonElement>;
  loading?: boolean;
}) => {
  return (
    <Button ref={inputRef} {...props} disabled={loading}>
      {children}
      {loading && <CenteredCircularProgress size={20} thickness={6} />}
    </Button>
  );
};
