import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import useSWR, { KeyedMutator } from 'swr';
import client from '.';
import { ErrorResponse, Instance, InstanceAction } from '../../types/models';
import type { components } from '../../types/openapi';
import { useUser } from './user';

type VastInstanceOffers = components['schemas']['VastInstanceOffers'];
type InstancesResponse = components['schemas']['InstancesResponse'];

export const useOffers = () => {
  const { data, error, isLoading, isValidating, mutate } = useSWR<
    VastInstanceOffers,
    ErrorResponse
  >('/instances/offers', client.get, { revalidateOnFocus: false });

  return {
    offers: data?.offers,
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

const createInstance = async (
  navigate: NavigateFunction,
  mutateOffers: KeyedMutator<VastInstanceOffers>,
  username: string,
  offerId: number
) => {
  enqueueSnackbar('Deploying instance...', { variant: 'info' });

  let created: Instance;

  try {
    created = (await client.post(`/instances/${username}`, {
      offer_id: offerId,
    })) as Instance;
  } catch (error) {
    console.error(error);
    enqueueSnackbar('Failed to deploy instance', { variant: 'error' });
    throw error;
  }

  enqueueSnackbar('Instance deployed', {
    variant: 'success',
    action: (
      <Button
        variant="text"
        color="inherit"
        size="small"
        onClick={() => navigate(`/instances/#${created.instance_id}`)}
      >
        View
      </Button>
    ),
  });

  // Remove the offer from the cache
  mutateOffers(undefined, {
    populateCache: (_, offers) => {
      if (!offers) return { offers: [] };
      return {
        offers: offers.offers.filter((offer) => offer.offer_id !== offerId),
      };
    },
  });

  return { instances: [created] };
};

const deleteInstance = async (username: string, instanceId: number) => {
  enqueueSnackbar(`Destroying instance ${instanceId}...`, {
    variant: 'info',
  });

  try {
    await client.delete(`/instances/${username}/${instanceId}`);
  } catch (error) {
    console.error(error);
    enqueueSnackbar(`Failed to destroy instance ${instanceId}`, {
      variant: 'error',
    });
    throw error;
  }

  enqueueSnackbar(`Instance ${instanceId} destroyed`, { variant: 'success' });

  return { instances: [] };
};

const updateInstance = async (
  username: string,
  instanceId: number,
  action: InstanceAction
) => {
  enqueueSnackbar(`Updating instance ${instanceId}...`, {
    variant: 'info',
  });

  try {
    await client.patch(`/instances/${username}/${instanceId}`, { action });
  } catch (error) {
    console.error(error);
    enqueueSnackbar(`Failed to update instance ${instanceId}`, {
      variant: 'error',
    });
    throw error;
  }

  enqueueSnackbar(`Instance ${instanceId} updated`, { variant: 'success' });

  return { instances: [] };
};

export const useInstances = () => {
  const navigate = useNavigate();
  const { mutate: mutateOffers } = useOffers();
  const { user } = useUser();
  const { data, error, isLoading, isValidating, mutate } = useSWR<
    InstancesResponse,
    ErrorResponse
  >(
    () => {
      // @ts-expect-error
      return `/instances/${user.username}`;
    },
    client.get,
    { revalidateOnFocus: false }
  );

  const deploy = useCallback(
    async (offerId: number) => {
      await mutate(
        async () =>
          await createInstance(
            navigate,
            mutateOffers,
            // @ts-expect-error
            user.username,
            offerId
          ),
        {
          populateCache: (created, current) => {
            if (!current) return { instances: [] };
            return {
              instances: [...current.instances, ...created.instances],
            };
          },
        }
      );
    },
    [mutate, mutateOffers, navigate, user]
  );

  const remove = useCallback(
    async (instanceId: number) => {
      await mutate(
        async () =>
          await deleteInstance(
            // @ts-expect-error
            user.username,
            instanceId
          ),
        {
          populateCache: (_, current) => {
            if (!current) return { instances: [] };
            return {
              instances: current.instances.filter(
                (instance) => instance.instance_id !== instanceId
              ),
            };
          },
        }
      );
    },
    [mutate, user]
  );

  const update = useCallback(
    async (instanceId: number, action: InstanceAction) => {
      await mutate(
        async () =>
          await updateInstance(
            // @ts-expect-error
            user.username,
            instanceId,
            action
          ),
        {
          populateCache: (_, current) => current ?? { instances: [] },
        }
      );
    },
    [mutate, user]
  );

  return {
    instances: data?.instances,
    error,
    isLoading,
    isValidating,
    mutate,
    refresh: () => mutate(),
    deploy,
    remove,
    update,
  };
};
