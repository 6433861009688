import { Box, Paper, Slide, Stack, styled } from '@mui/material';
import { Node, Panel } from '@xyflow/react';
import { memo } from 'react';
import { useLayerArgs } from '../hooks';
import { IOInfo, LayerNode, ParamInfo } from '../Layer';
import { isLayerNode } from '../util';
import { InspectorField, InspectorHeading, LayerIOField } from './fields';
import { InspectorProps } from './inspector';

const InspectorContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  width: 250,
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.divider} ${theme.palette.background.default}`,
}));
InspectorContainer.defaultProps = { elevation: 4 };

const InspectorItem = styled(Stack)(({ theme }) => ({
  '&:not(:last-child)': {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
InspectorItem.defaultProps = { spacing: 1 };

const Inspector = memo(({ selection, direction, ...rest }: InspectorProps) => {
  const { nodes } = selection;
  const show = nodes.length > 0;

  return (
    <Panel {...rest}>
      <Slide in={show} direction={direction} mountOnEnter unmountOnExit>
        <InspectorContainer>
          {nodes.map((node) =>
            isLayerNode(node) ? (
              <InspectorLayer key={node.id} layer={node} />
            ) : (
              <InspectorNode key={node.id} node={node} />
            )
          )}
        </InspectorContainer>
      </Slide>
    </Panel>
  );
});

export default Inspector;

const InspectorNode = memo(({ node }: { node: Node }) => {
  return (
    <InspectorItem>
      <InspectorHeading>Node Properties</InspectorHeading>
      <InspectorField label="ID" value={node.id} disabled />
      <InspectorField label="Node Type" value={node.type} disabled />
    </InspectorItem>
  );
});

const InspectorLayer = memo(({ layer }: { layer: LayerNode }) => {
  const { id } = layer;
  const { name, parameters, inputs, outputs } = layer.data;

  return (
    <InspectorItem>
      <LayerProperties id={id} name={name} />
      <LayerParameters id={id} params={parameters} />
      <LayerInputs id={id} inputs={inputs} />
      <LayerOutputs id={id} outputs={outputs} />
    </InspectorItem>
  );
});

const LayerProperties = memo(({ id, name }: { id: string; name: string }) => {
  const [args, setArgs] = useLayerArgs(id);

  const label = args._label || '';
  const setLabel = (e: React.ChangeEvent<HTMLInputElement>) =>
    setArgs({ ...args, _label: e.target.value });

  return (
    <Box>
      <InspectorHeading>Layer Properties</InspectorHeading>
      <InspectorField label="ID" value={id} disabled />
      <InspectorField label="Layer Name" value={name} disabled />
      <InspectorField label="Label" value={label} onChange={setLabel} />
    </Box>
  );
});

const LayerParameters = memo(
  ({ id, params }: { id: string; params?: ParamInfo[] }) => {
    const [args, setArgs] = useLayerArgs(id);
    if (!params || params.length === 0) return null;

    const onChange =
      (name: string) => (e: React.ChangeEvent<HTMLInputElement>) =>
        setArgs({ ...args, [name]: e.target.value });

    return (
      <Box>
        <InspectorHeading>Parameters</InspectorHeading>
        {params.map((param) => (
          <InspectorField
            key={param.name}
            label={param.name}
            value={args[param.name] || ''}
            placeholder={param.default}
            onChange={onChange(param.name)}
          />
        ))}
      </Box>
    );
  }
);

const LayerInputs = memo(
  ({ id, inputs }: { id: string; inputs?: IOInfo[] }) => {
    if (!inputs || inputs.length === 0) return null;

    return (
      <Box>
        <InspectorHeading>Inputs</InspectorHeading>
        {inputs.map((input) => (
          <LayerIOField
            key={input.name}
            layerId={id}
            name={input.name}
            type="input"
          />
        ))}
      </Box>
    );
  }
);

const LayerOutputs = memo(
  ({ id, outputs }: { id: string; outputs?: IOInfo[] }) => {
    if (!outputs || outputs.length === 0) return null;

    return (
      <Box>
        <InspectorHeading>Outputs</InspectorHeading>
        {outputs.map((output) => (
          <LayerIOField
            key={output.name}
            layerId={id}
            name={output.name}
            type="output"
          />
        ))}
      </Box>
    );
  }
);
