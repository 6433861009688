import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';

export const DataText = ({ label, value }: { label: string; value: any }) => {
  let fontFamily = 'unset';

  switch (typeof value) {
    case 'boolean':
      value = value ? 'Yes' : 'No';
      break;
    case 'object':
      if (!value) {
        value = 'None';
        break;
      }
      value = JSON.stringify(value);
      fontFamily = 'monospace';
      break;
  }

  return (
    <Stack>
      <Typography
        sx={{
          fontSize: '0.8rem',
          color: 'text.secondary',
          fontWeight: 'medium',
        }}
      >
        {label}
      </Typography>
      <Typography
        title={value}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '0.9rem',
          fontFamily,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export const DataPane = ({
  data,
  columnWidth = 4,
}: {
  data: Record<string, any>;
  columnWidth?: number;
}) => {
  columnWidth = Math.floor(columnWidth);
  const numColumns = Math.floor(12 / columnWidth);
  const columnSelector = `&:not(:nth-of-type(${numColumns}n))`;

  return (
    <Grid container columnSpacing={2} minWidth={0}>
      {Object.entries(data).map(([label, value]) => (
        <Grid
          item
          key={label}
          xs={columnWidth}
          p={1}
          sx={{
            [columnSelector]: {
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          <DataText key={label} label={label} value={value} />
        </Grid>
      ))}
    </Grid>
  );
};

export const DataPanel = ({
  title,
  data,
  columnWidth = 4,
}: {
  title: string;
  data: Record<string, any>;
  columnWidth?: number;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        onClick={() => setOpen(!open)}
        sx={{ cursor: 'pointer', userSelect: 'none' }}
      >
        {open ? <ExpandMore /> : <ChevronRight />}
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'medium',
          }}
          gutterBottom
        >
          {title}
        </Typography>
      </Stack>
      <Collapse in={open} timeout="auto">
        <DataPane data={data} columnWidth={columnWidth} />
      </Collapse>
    </Box>
  );
};
