import { Brightness4, Brightness7 } from '@mui/icons-material';
import {
  createTheme,
  IconButton,
  PaletteMode,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { createContext, useCallback, useContext, useMemo } from 'react';
import useSavedState from '../utils/useSavedState';

type ColorModeContextType = {
  mode: PaletteMode;
  toggleMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>({
  mode: 'light',
  toggleMode: () => {},
});

export function ToggleColorModeButton() {
  const { mode, toggleMode } = useColorMode();

  return (
    <IconButton onClick={toggleMode} color="inherit">
      {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
    </IconButton>
  );
}

export const useColorMode = () => {
  return useContext(ColorModeContext);
};

export const ColorModeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [mode, setMode] = useSavedState<PaletteMode>('colorMode', () => {
    const prefersDarkMode = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;
    return prefersDarkMode ? 'dark' : 'light';
  });

  const toggleMode = useMemo(() => {
    return () => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };
  }, [setMode]);

  const innerTheme = useCallback(
    (outerTheme: Theme) =>
      createTheme({
        ...outerTheme,
        palette: {
          mode,
          primary: outerTheme.palette.primary,
          secondary: outerTheme.palette.secondary,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={{ mode, toggleMode }}>
      <ThemeProvider theme={innerTheme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};
