import { Refresh } from '@mui/icons-material';
import { IconButton, Paper, Slide, SlideProps, styled } from '@mui/material';
import { Panel, PanelProps, useReactFlow } from '@xyflow/react';
import { useClipboard } from './Clipboard';
import { useForceUpdate } from './hooks';

export type DebugPanelProps = {
  direction: SlideProps['direction'];
  show: boolean;
} & Omit<PanelProps, 'children'>;

const DebugContainer = styled(Paper)(({ theme }) => ({
  height: '100%',
  width: '600px',
  borderRadius: 0,
  padding: theme.spacing(2),
  opacity: 0.75,
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.divider} ${theme.palette.background.default}`,
}));

const DebugDisplay = styled('pre')(({ theme }) => ({
  fontFamily: 'monospace',
  fontSize: '0.75em',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  lineHeight: 1.25,
  margin: 0,
  padding: 0,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

export function DebugPanel(props: DebugPanelProps) {
  const { direction, show, ...rest } = props;
  const { toObject } = useReactFlow();
  const { clipboard } = useClipboard();
  const forceUpdate = useForceUpdate();

  const data = toObject() as Record<string, any>;
  data.clipboard = clipboard;

  return (
    <Panel {...rest}>
      <Slide direction={direction} in={show} mountOnEnter unmountOnExit>
        <DebugContainer>
          <StyledIconButton onClick={forceUpdate}>
            <Refresh />
          </StyledIconButton>
          <DebugDisplay>{JSON.stringify(data, null, 2)}</DebugDisplay>
        </DebugContainer>
      </Slide>
    </Panel>
  );
}
