import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import client from '.';
import { ErrorResponse, TokenResponse, User } from '../../types/models';

export const useUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: user,
    error,
    isLoading,
  } = useSWR<User, ErrorResponse>('/me', client.get);

  useEffect(() => {
    if (error) {
      console.error(error);
      if (typeof error.detail === 'string') {
        enqueueSnackbar(error.detail, { variant: 'error' });
      }
      navigate('/login', {
        replace: true,
        state: {
          from: location.pathname,
        },
      });
    }
  }, [error, navigate, location.pathname]);

  return { user, error, isLoading };
};

export const useLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | undefined>();

  const login = async (username: string, password: string) => {
    setIsLoading(true);
    try {
      const token = await client.post<never, TokenResponse>('/login', {
        username,
        password,
      });
      localStorage.setItem('token', JSON.stringify(token));
      mutate('/me', undefined, false);
      enqueueSnackbar('Welcome back!', { variant: 'success' });
      navigate(location.state?.from || '/');
    } catch (error) {
      setError(error as ErrorResponse);
    } finally {
      setIsLoading(false);
    }
  };

  return { login, error, isLoading };
};

export const useLogout = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const logout = async () => {
    localStorage.removeItem('token');
    mutate('/me', undefined, false);
    enqueueSnackbar('Logged out successfully', { variant: 'success' });
    navigate('/login');
  };
  return logout;
};
