import { styled, TextField, Typography } from '@mui/material';
import { useHandleConnections, useReactFlow } from '@xyflow/react';
import { LayerNode } from '../Layer';
import { InspectorFieldProps, LayerIOFieldProps } from './inspector';

export const InspectorHeading = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 'medium',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export function InspectorField(props: InspectorFieldProps) {
  const { readOnly = false, ...rest } = props;

  return (
    <TextField
      margin="dense"
      size="small"
      variant="outlined"
      fullWidth
      inputProps={{ readOnly, ...rest.inputProps }}
      InputLabelProps={{ shrink: true, ...rest.InputLabelProps }}
      onKeyDown={(e) => e.stopPropagation()}
      {...rest}
    />
  );
}

export function LayerIOField(props: LayerIOFieldProps) {
  const { getNode } = useReactFlow();
  const { layerId, name, type, ...rest } = props;

  const connections = useHandleConnections({
    nodeId: layerId,
    id: name,
    type: type === 'input' ? 'target' : 'source',
  });

  const value = connections
    .map((conn) => {
      let targetId, targetHandle;
      if (type === 'input') {
        targetId = conn.source;
        targetHandle = conn.sourceHandle;
      } else {
        targetId = conn.target;
        targetHandle = conn.targetHandle;
      }

      const node = getNode(targetId) as LayerNode;
      return `${node.data.arguments?._label || node.data.name}::${targetHandle}`;
    })
    .join(', ');

  return <InspectorField label={name} value={value} disabled {...rest} />;
}
