import {
  Add,
  ArrowDropDown,
  DeleteForever,
  ModelTraining,
  PlayArrow,
  RestartAlt,
  Stop,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grow,
  InputLabel,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createContext, useContext, useRef, useState } from 'react';
import { LoadingButton } from '../../pages/Loading';
import { Instance } from '../../types/models';
import { useInstances } from '../../utils/api/instances';
import { getTimeAgo } from '../../utils/time';
import { DataPanel } from '../Data';

type Dataset = {
  dataset: string;
  version: string;
};

type Model = string;

type InstanceCardContextType = {
  isLoading: boolean;
  instance: Instance;
};

const InstanceCardContext = createContext<InstanceCardContextType>({
  isLoading: false,
  instance: {} as Instance,
});

export default function InstanceCard({
  instance,
  isLoading = false,
}: {
  instance: Instance;
  isLoading?: boolean;
}) {
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [dialogState, setDialogState] = useState({
    dataset: false,
    model: false,
  });

  const [dataset, setDataset] = useState<Dataset>({
    dataset: '',
    version: '',
  });

  const [model, setModel] = useState<Model>('');

  return (
    <InstanceCardContext.Provider value={{ instance, isLoading }}>
      <Card variant="outlined">
        <InstanceHeader />
        <Divider />
        <Stack
          p={2}
          spacing={2}
          bgcolor={(theme) => darken(theme.palette.background.paper, 0.015)}
        >
          <DataPanel
            title="Instance Details"
            data={instance.data}
            columnWidth={isMD ? 4 : isSM ? 6 : 12}
          />
          <Button
            onClick={() => setDialogState({ dataset: true, model: false })}
            variant="outlined"
            sx={{
              flexShrink: 0,
              flexDirection: 'column',
            }}
          >
            Select Dataset
            <Add />
          </Button>
          <Button
            onClick={() => setDialogState({ dataset: false, model: true })}
            variant="outlined"
            sx={{
              flexShrink: 0,
              flexDirection: 'column',
            }}
          >
            Select Model
            <Add />
          </Button>
        </Stack>
      </Card>

      <DatasetDialog
        open={dialogState.dataset}
        selectedDataset={dataset}
        onSubmit={setDataset}
        onClose={() => setDialogState({ dataset: false, model: false })}
      />

      <ModelDialog
        open={dialogState.model}
        selectedModel={model}
        onSubmit={setModel}
        onClose={() => setDialogState({ dataset: false, model: false })}
      />
    </InstanceCardContext.Provider>
  );
}

function InstanceHeader() {
  const { instance } = useContext(InstanceCardContext);

  return (
    <Stack
      p={2}
      direction="row"
      gap={2}
      flexWrap="wrap"
      justifyContent="space-between"
      bgcolor={(theme) => darken(theme.palette.background.paper, 0.03)}
    >
      <Stack direction="row" gap={2} alignItems="center" minWidth={0}>
        <InstanceLogo />
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <InstanceStatusChip />
            <Typography
              sx={{
                typography: 'h6',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                minWidth: 0,
              }}
            >
              Instance {instance.instance_id}
            </Typography>
          </Stack>
          <Typography color="text.secondary" sx={{ fontSize: '0.85rem' }}>
            Deployed {getTimeAgo(instance.data.started)} ago
          </Typography>
        </Box>
      </Stack>
      <InstanceActions />
    </Stack>
  );
}

function InstanceLogo() {
  const { instance } = useContext(InstanceCardContext);
  const logoUrl = instance.data.logo;
  return (
    <Avatar
      variant="rounded"
      src={'https://cloud.vast.ai' + logoUrl}
      sx={{
        width: 52,
        height: 52,
      }}
    />
  );
}

function InstanceStatusChip() {
  const { instance } = useContext(InstanceCardContext);
  const { status } = instance.data;

  let [color, label] = ['warning', 'Unknown'];

  switch (status) {
    case 'starting':
      [color, label] = ['info', 'Starting'];
      break;
    case 'resuming':
      [color, label] = ['info', 'Resuming'];
      break;
    case 'running':
      [color, label] = ['primary', 'Running'];
      break;
    case 'stopping':
      [color, label] = ['warning', 'Stopping'];
      break;
    case 'stopped':
      [color, label] = ['error', 'Stopped'];
      break;
    case 'rebooting':
      [color, label] = ['warning', 'Rebooting'];
      break;
  }

  return <Chip size="small" label={label} color={color as any} />;
}

function InstanceActions() {
  const { remove, update } = useInstances();
  const { instance, isLoading } = useContext(InstanceCardContext);
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Box ml="auto">
      <ButtonGroup size="small" variant="contained" color="primary">
        <LoadingButton loading={isLoading} startIcon={<ModelTraining />}>
          Start Training
        </LoadingButton>
        <LoadingButton
          inputRef={anchorRef}
          loading={isLoading}
          endIcon={<ArrowDropDown />}
          onClick={() => setOpenMenu(!openMenu)}
        >
          Manage Instance
        </LoadingButton>
      </ButtonGroup>
      <DropdownMenu
        open={openMenu}
        handleClose={() => setOpenMenu(false)}
        popperPlacement="bottom-end"
        transformOrigin="top right"
        anchorEl={anchorRef}
      >
        <MenuItem onClick={() => update(instance.instance_id, 'stop')}>
          <ListItemIcon>
            <Stop />
          </ListItemIcon>
          Stop Instance
        </MenuItem>
        <MenuItem onClick={() => update(instance.instance_id, 'start')}>
          <ListItemIcon>
            <PlayArrow />
          </ListItemIcon>
          Start Instance
        </MenuItem>
        <MenuItem onClick={() => update(instance.instance_id, 'reboot')}>
          <ListItemIcon>
            <RestartAlt />
          </ListItemIcon>
          Reboot Instance
        </MenuItem>
        <MenuItem onClick={() => remove(instance.instance_id)}>
          <ListItemIcon>
            <DeleteForever />
          </ListItemIcon>
          Destroy Instance
        </MenuItem>
      </DropdownMenu>
    </Box>
  );
}

type DropdownMenuProps = {
  anchorEl: React.RefObject<HTMLButtonElement>;
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  popperPlacement: PopperPlacementType;
  transformOrigin: string;
};

function DropdownMenu(props: DropdownMenuProps) {
  const {
    anchorEl,
    open,
    handleClose,
    children,
    popperPlacement,
    transformOrigin,
  } = props;

  return (
    <Popper
      open={open}
      anchorEl={anchorEl.current}
      placement={popperPlacement}
      transition
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: transformOrigin || 'top right',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

type DatasetDialogProps = {
  open: boolean;
  selectedDataset: { dataset: string; version: string };
  onSubmit: (dataset: Dataset) => void;
  onClose: () => void;
};

function DatasetDialog(props: DatasetDialogProps) {
  const { open, selectedDataset, onSubmit, onClose } = props;
  const [dataset, setDataset] = useState(selectedDataset);

  const handleSelect = (e: SelectChangeEvent) => {
    setDataset((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(dataset);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle>Select Dataset</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a dataset to use for training your model.
        </DialogContentText>
        <FormControl fullWidth margin="normal">
          <InputLabel id="dataset-select-label">Dataset</InputLabel>
          <Select
            labelId="dataset-select-label"
            id="dataset-select"
            label="Dataset"
            value={dataset.dataset}
            name="dataset"
            onChange={handleSelect}
          >
            <MenuItem value="dataset1">Dataset 1</MenuItem>
            <MenuItem value="dataset2">Dataset 2</MenuItem>
            <MenuItem value="dataset3">Dataset 3</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="dataset-version-select-label">Version</InputLabel>
          <Select
            labelId="dataset-version-select-label"
            id="dataset-version-select"
            label="Version"
            value={dataset.version}
            name="version"
            onChange={handleSelect}
          >
            <MenuItem value="version1">Version 1</MenuItem>
            <MenuItem value="version2">Version 2</MenuItem>
            <MenuItem value="version3">Version 3</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Select</Button>
      </DialogActions>
    </Dialog>
  );
}

type ModelDialogProps = {
  open: boolean;
  selectedModel: string;
  onSubmit: (model: Model) => void;
  onClose: () => void;
};

function ModelDialog(props: ModelDialogProps) {
  const { open, selectedModel, onSubmit, onClose } = props;
  const [model, setModel] = useState(selectedModel);

  const handleSelect = (e: any) => {
    setModel(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(model);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle>Select Model</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a model to use for training your model.
        </DialogContentText>
        <FormControl fullWidth margin="normal">
          <InputLabel id="model-select-label">Model</InputLabel>
          <Select
            labelId="model-select-label"
            id="model-select"
            label="Model"
            value={model}
            onChange={handleSelect}
          >
            <MenuItem value="model1">Model 1</MenuItem>
            <MenuItem value="model2">Model 2</MenuItem>
            <MenuItem value="model3">Model 3</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Select</Button>
      </DialogActions>
    </Dialog>
  );
}
