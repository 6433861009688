import { Box, Paper, Stack, styled, Typography } from '@mui/material';
import { amber, blueGrey, purple } from '@mui/material/colors';
import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { memo } from 'react';

export type IOInfo = {
  name: string;
  type?: string;
};

export type LayerHandleProps = {
  type: 'input' | 'output';
  info: IOInfo;
};

const StyledHandle = styled(Handle)<{ iotype?: string }>(({ iotype }) => {
  let color: string = blueGrey[300];
  let height: number = 8;
  switch (iotype) {
    case 'bool':
      color = blueGrey[300];
      break;
    case 'tensor':
      color = purple[300];
      break;
    case 'tensors':
      color = purple[600];
      height = 12;
      break;
    case 'train_config':
      color = amber[300];
      break;
  }

  return {
    backgroundColor: color,
    borderRadius: 4,
    width: 8,
    height,
  };
});

export function LayerHandle({ type, info }: LayerHandleProps) {
  const { name, type: iotype } = info;
  const handleType = type === 'input' ? 'target' : 'source';
  const position = type === 'input' ? Position.Left : Position.Right;

  return (
    <Box position="relative" px={1.5}>
      <StyledHandle
        id={name}
        type={handleType}
        position={position}
        iotype={iotype}
      />
      <Typography align={position} fontFamily="monospace" fontSize="0.75em">
        {name}
      </Typography>
    </Box>
  );
}

export type LayerIOProps = {
  inputs: IOInfo[];
  outputs: IOInfo[];
};

export function LayerIO({ inputs, outputs }: LayerIOProps) {
  return (
    <Stack direction="row" justifyContent="space-between" py={1}>
      <Stack>
        {inputs.map((input) => (
          <LayerHandle key={input.name} type="input" info={input} />
        ))}
      </Stack>
      <Stack>
        {outputs.map((output) => (
          <LayerHandle key={output.name} type="output" info={output} />
        ))}
      </Stack>
    </Stack>
  );
}

export type ParamInfo = {
  name: string;
  type: string;
  default?: any;
  options?: any[];
  [key: string]: any;
};

export type LayerData = {
  label?: string;
  name: string;
  inputs?: IOInfo[];
  outputs?: IOInfo[];
  parameters?: ParamInfo[];
  arguments?: Record<string, any>;
};

export type LayerBaseProps<T extends Node> = {
  nodeProps: NodeProps<T>;
  layerData: LayerData;
  children?: React.ReactNode;
};

const LayerContainer = styled(Paper)<{ selected: boolean | undefined }>(
  ({ theme, selected }) => ({
    boxShadow: selected
      ? `0 0 4px 1px ${theme.palette.primary.light}`
      : `0 0 0 1px ${theme.palette.divider}`,
    '& > :not(:last-child)': {
      boxShadow: `0 1px 0 0 ${theme.palette.divider}`,
    },
  })
);

export const LayerBase = memo(
  <T extends Node>({ nodeProps, layerData, children }: LayerBaseProps<T>) => {
    const { selected } = nodeProps;
    const { name, inputs = [], outputs = [] } = layerData;

    return (
      <LayerContainer selected={selected}>
        <Typography textAlign="center" fontSize="0.875em" lineHeight={2} px={2}>
          {layerData.arguments?._label || name}
        </Typography>
        <LayerIO inputs={inputs} outputs={outputs} />
        {children}
      </LayerContainer>
    );
  }
);

export type LayerNode = Node<LayerData>;

export function Layer(props: NodeProps<LayerNode>) {
  return <LayerBase nodeProps={props} layerData={props.data} />;
}
